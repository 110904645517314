import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react'
import {BrowserRouter as Router, Route, NavLink} from 'react-router-dom';
import { Navigator, Main, Download } from './components';
import Amplify, { API, Auth, Hub } from "aws-amplify";
//import awsExports from "./aws-exports";
//import awsExports from "./xsquare-config";
//Amplify.configure(awsExports);

Amplify.configure({
    // OPTIONAL - if your API requires authentication
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
        // REQUIRED - Amazon Cognito Region
        region: "ap-southeast-1",
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: "ap-southeast-1_zn1gmcBlF",
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: "nat6n1mebgqsho5kfq470vbf0",
    },
    API: {
        endpoints: [
            {
                name: "xsquare customers",
                endpoint: "https://vozko2kssc.execute-api.ap-southeast-1.amazonaws.com/prod"
		//endpoint: "https://api.xsquareportal.com/prod"
            }
        ]
    }
});


class App extends Component {

    constructor(props){
        
        super(props)

        //console.log("App.constructor");

        this.state = { user: null }

        Hub.listen('auth', (data) => {
            const { payload } = data;
            this.onAuthEvent(payload);           
        })
    }

  
    componentDidMount() {
        //console.log("App.componentDidMount");    
        this.loadUser();
    }

    loadUser() {
        Auth.currentAuthenticatedUser()
        .then(user => this.setState({ user: user }))
        .catch(err => this.setState({ user: null }));
    }
  
  
    onAuthEvent(payload) {
        this.loadUser();
    }

    
    render() {
        //const state = this.state;
        //const username = this.state.username;
        //console.log("username: " + username);
        if ( null == this.state.user ) {
            // TODO: at the moment we  are using the built-in UI for login. Suppress rendering anything when not logged in 
            // as MS Edge will not display things properly otherwise
            return null
        }

        return (
            <React.Fragment>
                <Main state={this.state}/>
                <Navigator state={this.state}/>
            </React.Fragment>
        );
    }
}


export default withAuthenticator(App, true);

