import React, { Component } from 'react';
import { Navbar, Nav, BSpan } from 'bootstrap-4-react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Auth, Hub, Logger } from 'aws-amplify';
import { SignOut } from 'aws-amplify-react';

const HomeItems = props => (
  <React.Fragment>
    <Nav.ItemLink href="#/" active>
      Home
      <BSpan srOnly>(current}</BSpan>
    </Nav.ItemLink>
  </React.Fragment>
)

const LoginItems = props => (
  <React.Fragment>
    <Nav.ItemLink href="#/">
      Home
    </Nav.ItemLink>
  </React.Fragment>
)

const logger = new Logger('Navigator');

export default class Navigator extends Component {
    
    
  constructor(props) {
    
    super(props);
  }


  render() {
      
    const { user } = this.props.state;

    return (
      <Navbar expand="md" dark bg="dark" fixed="top">
        <Navbar.Toggler target="#navbarsExampleDefault" />

        <Navbar.Collapse id="navbarsExampleDefault">
          <Navbar.Nav mr="auto">
            <HashRouter>
              <Switch>
                <Route exact path="/" component={HomeItems} />
                <Route exact path="/login" component={LoginItems} />
              </Switch>
            </HashRouter>
          </Navbar.Nav>
          <Navbar.Text mr="2">
            { user? 'Logged in as: ' + user.username : 'Please sign in' }
          </Navbar.Text>
          { user && <SignOut /> }
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
