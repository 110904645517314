import React, { Component } from 'react';
import { Container } from 'bootstrap-4-react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Auth, Hub, Logger } from 'aws-amplify';

import { Home, Login } from '../pages';
import Amplify, { API } from "aws-amplify";


const logger = new Logger('Main');
const apiName = 'xsquare customers';
const path = '/ride';

export default class Main extends Component {
    
    constructor(props) {
      
        super(props);

        this.handler = this.handler.bind(this);
    }


    downloadFile() {

        Auth.currentAuthenticatedUser().then((user) => {
            let jwtToken = user.signInUserSession.idToken.jwtToken;
            const options = {
                headers: {
                    Authorization: jwtToken
                }
            }
            API.post(apiName, path, options).then(response => {
                //console.log(response.download_url);
                window.open(response.download_url, "_self");
            }).catch(error => {
                console.log(error)
                // TODO: show error message
            });
        }).catch(error => {
            // usually when user is "Not Authenticated" yet
            console.log(error)
            // TODO: show error message
        });
    }

    
    handler(event) {
        event.preventDefault();
        this.downloadFile();
    }    
    
    

    render() {
      
        let state = this.props.state;

        return (
          <Container as="main" role="main">
            <div className="starter-template">
              <HashRouter>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={(props) => <Home user={state} handler={this.handler} />}
                  />
                  <Route
                    exact
                    path="/login"
                    render={(props) => <Login user={state} />}
                  />
                </Switch>
              </HashRouter>
            </div>
          </Container>
        )
    }
}
