import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//import Amplify, {API} from "aws-amplify";
//import awsExports from "./aws-exports";
//import awsExports from "./xsquare-config";
//Amplify.configure(awsExports);
/*
Amplify.configure({
    // OPTIONAL - if your API requires authentication 
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
        // REQUIRED - Amazon Cognito Region
        region: "ap-southeast-1", 
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: "ap-southeast-1_zn1gmcBlF", 
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: "nat6n1mebgqsho5kfq470vbf0",
    },
    API: {
        endpoints: [
            {
                name: "xsquare customers",
                endpoint: "https://vozko2kssc.execute-api.ap-southeast-1.amazonaws.com/prod"
            }
        ]
    }
});
*/

/*
  <React.StrictMode>
    <App />
  </React.StrictMode>,
*/
ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
