import React, { Component } from 'react';
import { Lead, BSpan } from 'bootstrap-4-react';

export default class Home extends Component {



    
  render() {
    
    if (null == this.props.user) {
        return null
    }
    
/*
                <a className="App-link" target="_blank" rel="noopener noreferrer" href={user.response.download_url}>Download AGV data export</a>
*/
    return (
      <React.Fragment>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
        <div>
            <header>
                <a className="App-link" target="_blank" rel="noopener noreferrer" href="#" onClick={this.props.handler}>Download AGV data export</a>
            </header>
        </div>
        
      </React.Fragment>
    )
  }
}
